<template>
  <v-bottom-navigation
    :class="{isIOS13: isIOS13Device}"
    fixed
    bottom
    grow
    v-model="activeFolder"
  >
    <v-btn
      value='recent'
      class='bottom-navigation-menu-item'
      active-class="primary--text"
    >
      <span>Home</span>
      <v-icon>
        mdi-home
      </v-icon>
    </v-btn>
    <v-btn
      value='search'
      class='bottom-navigation-menu-item'
      active-class="primary--text"
    >
      Search
      <v-icon>
        mdi-magnify
      </v-icon>
    </v-btn>
    <v-btn
      value="home"
      class='bottom-navigation-menu-item'
      active-class="primary--text"
    >
      <span>Library</span>
      <v-icon>
        mdi-folder
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { isIOS13 } from 'mobile-device-detect'

export default {
  props: {
    value: {
      required: true
    }
  },
  name: 'MobileBottomNavigation',
  data: () => ({
    activeFolder: 'recent'
  }),
  computed: {
    isIOS13Device() {
      return isIOS13
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    activeFolder(to) {
      if (to === 'search') return
      this.$emit('setActive', to)
    }
  }
}
</script>
<style lang="scss">

 .isIOS13.v-bottom-navigation {
   height: auto !important;
 }

  .isIOS13.v-bottom-navigation .v-btn__content {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: constant( safe-area-inset-bottom ); /* move footer up on iPhone X - iOS 11.0 */
    margin-bottom: env( safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.2 */
  }

  .v-bottom-navigation  .v-btn:before {
    display: none !important;
  }
</style>